import React from 'react';
import about_img from '../images/about.jpg';
export default class About extends React.Component {
    render(){
        return (
            <section className="ftco-about img ftco-section ftco-no-pt ftco-no-pb" id="about-section">
                <div className="container">
                    <div className="row d-flex no-gutters">
                        <div className="col-md-6 col-lg-6 d-flex">
                            <div className="img-about img d-flex align-items-stretch">
                                <div className="overlay"></div>
                                <div className="img d-flex align-self-stretch align-items-center" style={{'backgroundImage':`url(${about_img})`}}>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 pl-md-5 py-5">
                            <div className="row justify-content-start pb-3">
                        <div className="col-md-12 heading-section ftco-animate">
                            <h1 className="big">About</h1>
                            <h2 className="mb-4">About Me</h2>
                            <p>Better to know who owns this website.</p>
                            <ul className="about-info mt-4 px-md-0 px-2">
                                <li className="d-flex"><span>{this.props.strings.name_title}</span> <span>{this.props.strings.name_value}</span></li>
                                <li className="d-flex"><span>{this.props.strings.postal_title}</span> <span>{this.props.strings.postal_value}</span></li>
                                <li className="d-flex"><span>{this.props.strings.email_title}</span> <span>{this.props.strings.email_value}</span></li>
                                <li className="d-flex"><span>{this.props.strings.phone_title} </span> <span>{this.props.strings.phone_value}</span></li>
                                
                            </ul>
                        </div>
                        </div>
                    <div className="counter-wrap ftco-animate d-flex mt-md-3">
                    <div className="text">
                        <p className="mb-4">
                            <span className="number" data-number={this.props.strings.project_value}>0</span>
                            <span> {this.props.strings.project_title}</span>
                        </p>
                        {/* <p><a href="#" className="btn btn-primary py-3 px-3">Download CV</a></p> */}
                    </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        );
    }
}