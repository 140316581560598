import React from 'react';

export default class Nav extends React.Component {
    render(){
        return (
            <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light site-navbar-target" id="ftco-navbar">
                <div className="container">
                    <a className="navbar-brand" href="index.html"><span>L</span>uthfi</a>
                    <button className="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="oi oi-menu"></span> Menu
                    </button>

                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav nav ml-auto">
                        <li className="nav-item"><a href="#home-section" className="nav-link"><span>{this.props.strings.home}</span></a></li>
                        <li className="nav-item"><a href="#about-section" className="nav-link"><span>{this.props.strings.about}</span></a></li>
                        <li className="nav-item"><a href="#resume-section" className="nav-link"><span>{this.props.strings.skills}</span></a></li>
                        <li className="nav-item"><a href="#services-section" className="nav-link"><span>{this.props.strings.resume}</span></a></li>
                        <li className="nav-item"><a href="#projects-section" className="nav-link"><span>{this.props.strings.project}</span></a></li>
                        {/* <li className="nav-item"><a href="#blog-section" className="nav-link"><span>My Blog</span></a></li> */}
                        <li className="nav-item"><a href="#contact-section" className="nav-link"><span>{this.props.strings.contact}</span></a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}