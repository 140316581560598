import React from 'react';
import Nav from './ListResume/Nav';
import Awards from './ListResume/Awards'
import Education from './ListResume/Education'
import Experience from './ListResume/Experience'
import Skills from './ListResume/Skills'
export default class Resume extends React.Component {
    render(){
        return (
            <section class="ftco-section ftco-no-pb goto-here" id="resume-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <Nav strings={this.props.strings}/>
                        </div>
                        <div class="col-md-9">
                            <Education strings={this.props.strings} />
                            <Experience strings={this.props.strings}/>
                            <Skills strings={this.props.strings}/>
                            <Awards strings={this.props.strings}/>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}