import React from 'react';

export default class Contact extends React.Component {
    render(){
        return (
            <section class="ftco-section contact-section ftco-no-pb" id="contact-section">
                <div class="container">
                    <div class="row justify-content-center mb-5 pb-3">
                        <div class="col-md-7 heading-section text-center ftco-animate">
                            <h1 class="big big-2">{this.props.strings.title}</h1>
                            <h2 class="mb-4">{this.props.strings.subtitle}</h2>
                            <p>{this.props.strings.description}</p>
                        </div>
                    </div>

                    <div class="row d-flex contact-info mb-5">
                        {this.props.strings.contact_list.map(x=>{
                            return (
                                <div class="col-md-6 col-lg-3 d-flex ftco-animate">
                                    <div class="align-self-stretch box text-center p-4 shadow">
                                        <div class="icon d-flex align-items-center justify-content-center">
                                            <span class={x.icon}></span>
                                        </div>
                                        <div>
                                            <h3 class="mb-4">{x.title}</h3>
                                            <p>{x.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        
                       
                    </div>

                </div>
            </section>
        );
    }
}