import React from 'react';

export default class Education extends React.Component {
    
    render(){
        return (
            <div id="page-1" class= "page one">
                <h2 class="heading">{ this.props.strings.education.title }</h2>
                { this.props.strings.education.list.map(x=>{
                    return (
                        <div class="resume-wrap d-flex ftco-animate">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <span class="flaticon-graduation-cap"></span>
                            </div>
                            <div class="text pl-3">
                                <span class="date">{ x.year }</span>
                                <h2>{ x.title }</h2>
                                <span class="position">{ x.position }</span>
                                <p>{ x.description }</p>
                            </div>
                        </div>
                    );
                })}
                {/* <div class="resume-wrap d-flex ftco-animate">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span class="flaticon-graduation-cap"></span>
                    </div>
                    <div class="text pl-3">
                        <span class="date">2014-2017</span>
                        <h2>Software Engineering Major</h2>
                        <span class="position">Vocational High School 11 Bandung</span>
                        <p>Graduates as Software Engineering Major from Vocational High School 11 Bandung</p>
                    </div>
                </div>
                <div class="resume-wrap d-flex ftco-animate">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span class="flaticon-graduation-cap"></span>
                    </div>
                    <div class="text pl-3">
                        <span class="date">2017-Current</span>
                        <h2>Diploma 4 Computer Science/Informatics Major</h2>
                        <span class="position">Politeknik Pos Indonesia</span>
                        <p>Currently Majoring Computer Science/Informatics as Diploma 4 with Grade Point Average of 3.7</p>
                    </div>
                </div>
                <div class="resume-wrap d-flex ftco-animate">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span class="flaticon-graduation-cap"></span>
                    </div>
                    <div class="text pl-3">
                        <span class="date">2019</span>
                        <h2><a href="https://drive.google.com/file/d/11PqxXMVDLFFs2OIDvK2HAmM9y4HYg_5f/view?usp=sharing" target="_blank">Junior Mobile Programmer</a></h2>
                        <span class="position">Kementrian Komunikasi dan Informatika</span>
                        <p>Received training from Kementrian Komunikasi dan Informatika which is held at Campus Politeknik Bandung</p>
                    </div>
                </div> */}
                {/* <div class="resume-wrap d-flex ftco-animate">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span class="flaticon-graduation-cap"></span>
                    </div>
                    <div class="text pl-3">
                        <span class="date">2014-2015</span>
                        <h2>Art &amp; Creative Director</h2>
                        <span class="position">Cambridge University</span>
                        <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
                    </div>
                </div> */}
            </div>
        );
    }
}