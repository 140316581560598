import React from 'react';

export default class Footer extends React.Component {
    render(){
        return (
            <footer className="ftco-footer ftco-section">
                <div className="container">
                    <div className="row mb-5">
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4">
                        <h2 className="ftco-heading-2">{this.props.strings.footer.about_title}</h2>
                        <p>{this.props.strings.footer.about_description}</p>
                        <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                            {/* <li className="ftco-animate"><a href="#"><span className="icon-twitter"></span></a></li> */}
                            <li className="ftco-animate"><a href="https://www.facebook.com/luthfi.m.nabil"><span className="icon-facebook"></span></a></li>
                            <li className="ftco-animate"><a href="https://www.instagram.com/luthfi.mnabil/"><span className="icon-instagram"></span></a></li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4 ml-md-4">
                        <h2 className="ftco-heading-2">Links</h2>
                        <ul className="list-unstyled">
                            <li><a href="#home-section"><span className="icon-long-arrow-right mr-2"></span>{this.props.strings.nav.home}</a></li>
                            <li><a href="#about-section"><span className="icon-long-arrow-right mr-2"></span>{this.props.strings.nav.about}</a></li>
                            <li><a href="#resume-section"><span className="icon-long-arrow-right mr-2"></span>{this.props.strings.nav.resume}</a></li>
                            <li><a href="#services-section"><span className="icon-long-arrow-right mr-2"></span>{this.props.strings.nav.skills}</a></li>
                            <li><a href="#contact-section"><span className="icon-long-arrow-right mr-2"></span>{this.props.strings.nav.contact}</a></li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4">
                        <h2 className="ftco-heading-2">Icons Author</h2>
                        <ul className="list-unstyled">
                            <li><a href="https://www.flaticon.com/authors/good-ware" title="Good Ware"><span className="icon-long-arrow-right mr-2"></span>Good Ware</a></li>
                            <li><a href="https://www.flaticon.com/authors/prettycons" title="prettycons"><span className="icon-long-arrow-right mr-2"></span>prettycons</a></li>
                            <li><a href="https://www.flaticon.com/authors/geotatah" title="geotatah"><span className="icon-long-arrow-right mr-2"></span>geotatah</a></li>
                            <li><a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp"><span className="icon-long-arrow-right mr-2"></span>Eucalyp</a></li>
                            <li><a href="https://www.flaticon.com/authors/freepik" title="Freepik"><span className="icon-long-arrow-right mr-2"></span>Freepik</a></li>
                            <li><a href="https://www.flaticon.com/authors/srip" title="srip"><span className="icon-long-arrow-right mr-2"></span>srip</a></li>
                            <li><a href="https://www.flaticon.com/authors/ultimatearm" title="ultimatearm"><span className="icon-long-arrow-right mr-2"></span>ultimatearm</a></li>
                            <li><a href="https://www.flaticon.com/"     title="Flaticon"><span className="icon-long-arrow-right mr-2"></span>flaticon.com</a></li>
                        </ul> 
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4">
                            <h2 className="ftco-heading-2">Have a Questions?</h2>
                            <div className="block-23 mb-3">
                            <ul>
                                <li><span className="icon icon-map-marker"></span><span className="text">Bandung</span></li>
                                <li><a href="#"><span className="icon icon-phone"></span><span className="text">+6283178127408</span></a></li>
                                <li><a href="#"><span className="icon icon-envelope"></span><span className="text">luthfi.nabil@elemen.web.id</span></a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12 text-center">
                        <p>{/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="icon-heart color-danger" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>
            {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}</p>
                    </div>
                    </div>
                </div>
                </footer>
        );
    }
}