import React from 'react';

export default class Projects extends React.Component {
    render(){
        return (
            <section class="ftco-section ftco-project" id="projects-section">
                <div class="container-fluid px-md-0">
                    <div class="row no-gutters justify-content-center pb-5">
                <div class="col-md-12 heading-section text-center ftco-animate">
                    <h1 class="big big-2">{this.props.strings.title}</h1>
                    <h2 class="mb-4">{this.props.strings.subtitle}</h2>
                    <p>{this.props.strings.description}</p>
                </div>
                </div>
                    <div class="row no-gutters">
                        { this.props.strings.data.map(x=>{
                            return(
                                <div class="col-md-4">
                                    <div class="project img ftco-animate d-flex justify-content-center align-items-center" style={{"backgroundImage":"url("+x.bgImage+")"}}>
                                        <div class="overlay"></div>
                                        <div class="text text-center p-4">
                                            <h3>{ x.description }</h3>
                                            <span>{ x.title }</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        );
    }
}