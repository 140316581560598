import React from 'react';

export default class Experience extends React.Component {
    render(){
        return (
            <div id="page-2" class= "page two">
                <h2 class="heading">{ this.props.strings.experience.title }</h2>
                { this.props.strings.experience.list.map(x=>{
                    return(
                        <div class="resume-wrap d-flex ftco-animate">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <span class="flaticon-experience"></span>
                            </div>
                            <div class="text pl-3">
                                <span class="date">{ x.year }</span>
                                <h2>{ x.title }</h2>
                                <span class="position">{ x.position }</span>
                                <p>{ x.description }</p>
                            </div>
                        </div>
                    )
                })}
                {/* <div class="resume-wrap d-flex ftco-animate">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span class="flaticon-experience"></span>
                    </div>
                    <div class="text pl-3">
                        <span class="date">2014-2015</span>
                        <h2>Wordpress Developer</h2>
                        <span class="position">Cambridge University</span>
                        <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
                    </div>
                </div>
                <div class="resume-wrap d-flex ftco-animate">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span class="flaticon-experience"></span>
                    </div>
                    <div class="text pl-3">
                        <span class="date">2017-2018</span>
                        <h2>UI/UX Designer</h2>
                        <span class="position">Cambridge University</span>
                        <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
                    </div>
                </div> */}
            </div>
        );
    }
}