import React from 'react';
export default {
    "en":{
        "nav":{
            "home":"Home",
            "about":"About",
            "skills":"Skills",
            "resume":"Resume",
            "project":"Project",
            "contact":"Contact"
        },
        "home":{
            "data-rotate":[ " Web Developer.", " Backend Developer.", "n Instructor.", " DevOps Engineer.", " System Analyst." ],
            "intro":"Hey! My Name is",
            "intro_2":"I'm a",
            "name":"Luthfi"
        },
        "about":{
            "title":"About",
            "subtitle":"About Me",
            "description":"Better to know who owns this website.",
            "name_title":"Name:",
            "name_value":"Luthfi Muhammad Nabil",
            "postal_title":"Current Role:",
            "postal_value":"Software Developer I - Backend",
            "email_title":"Email:",
            "email_value":"luthfi.nabil@elemen.web.id",
            "phone_title":"Phone Number:",
            "phone_value":"+62-831-7812-7408",
            "project_title":" Projects completed",
            "project_value":"21"
        },
        "resume":{
            "nav":{
                "education":"Education",
                "experience":"Experience",
                "skills":"Skills",
                "awards":"Awards"
            },
            "skills":{
                "title":"Skills"
            },
            "education":{
                "title":"Education",
                "list":[
                    {
                        "year":"2014-2017",
                        "title":"Software Engineering Major",
                        "position":"Vocational High School 11 Bandung",
                        "description":"Graduates as Software Engineering Major from Vocational High School 11 Bandung"
                    },
                    {
                        "year":"2017-2021",
                        "title":"Bachelor of Computer Science/Informatics Major",
                        "position":"Politeknik Pos Indonesia",
                        "description":"Currently Majoring Computer Science/Informatics as Bachelor with Grade Point Average of 3.7"
                    },
                    {
                        "year":"2019",
                        "title":<a href='https://drive.google.com/file/d/11PqxXMVDLFFs2OIDvK2HAmM9y4HYg_5f/view?usp=sharing' target='_blank'>Junior Mobile Programmer</a>,
                        "position":"Kementrian Komunikasi dan Informatika",
                        "description":"Received training from Kementrian Komunikasi dan Informatika which is held at Campus Politeknik Bandung"
                    }
                ]
            },
            "experience":{
                "title":"Experience",
                "list":[
                    {
                        "year":"2019 - Feb. 2020",
                        "title":"Fullstack Developer at SIMPRO Poltekpos",
                        "position":"Politeknik Pos Indonesia",
                        "description":"Developed Application for Managing Project Courses Activities at Politeknik Pos Indonesia"
                    },
                    {
                        "year":"Jan. 2020 - May 2020",
                        "title":"Lead Developer",
                        "position":"UPTD Tikomdik",
                        "description":"Leading some projects as a team and building Development Environment for every team."
                    },
                    {
                        "year":"Jan. 2020 - 2022",
                        "title":"Forum Atikan Backend Developer",
                        "position":"UPTD Tikomdik",
                        "description":"Currently developing some APIs such as Single Sign On on Forum Atikan Application."
                    },
                    {
                        "year":"May. 2020 - 2022",
                        "title":"DevOps Engineer",
                        "position":"UPTD Tikomdik",
                        "description":"Have positioned at DevOps Engineer as Intern in UPTD Tikomdik."
                    }
                ]
            },
            "job_experience":{
                "title":"Job Experience",
                "list":[
                    {
                        "year":"2019 - Feb. 2020",
                        "title":"Fullstack Developer at SIMPRO Poltekpos",
                        "position":"Politeknik Pos Indonesia",
                        "description":"Developed Application for Managing Project Courses Activities at Politeknik Pos Indonesia"
                    },
                    {
                        "year":"Jan. 2020 - May 2020",
                        "title":"Lead Developer",
                        "position":"UPTD Tikomdik",
                        "description":"Leading some projects as a team and building Development Environment for every team."
                    },
                    {
                        "year":"Jan. 2020 - 2022",
                        "title":"Forum Atikan Backend Developer",
                        "position":"UPTD Tikomdik",
                        "description":"Currently developing some APIs such as Single Sign On on Forum Atikan Application."
                    },
                    {
                        "year":"May. 2020 - 2022",
                        "title":"DevOps Engineer",
                        "position":"UPTD Tikomdik",
                        "description":"Have positioned at DevOps Engineer as Intern in UPTD Tikomdik."
                    }
                ]
            },
            "awards":{
                "title":"Awards",
                "list":[
                    {
                        "year":"2018",
                        "title":<>Java <a href="https://drive.google.com/file/d/1k43ved75xvwbvlqqQ_P1hjnbOzP3ZABR/view?usp=sharing" target="_blank">Fundamental</a> & <a href="https://drive.google.com/file/d/1FxSqh5v3KDqobi48a57cw_r1EpBFRIF4/view?usp=sharing" target="_blank">Programming</a> Certificate</>,
                        "position":"Oracle Academy",
                        "description":"Received Certificates from Oracle Academy."
                    },{
                        "year":"2019",
                        "title":<><a href="https://drive.google.com/file/d/1NpaOi0N8Jt4_WNkLQarnByY4sYWX1Q0c/view?usp=sharing" target="_blank">Junior Mobile Programmer</a></>,
                        "position":"LSP Komputer",
                        "description":"Received Junior Mobile Programmer Certificate that released by LSP Komputer."
                    },{
                        "year":"2021",
                        "title":"Azure AI Fundamentals",
                        "position":"Microsoft",
                        "description":"Received Azure AI Fundamentals (AI-900) Certificate that released by Microsoft"
                    }
                ]
            }
        },
        "services":{
            "title":"Skills",
            "subtitle":"Skills",
            "description":"Here is the list about what i could do based on Experience",
            "projects":[
                {
                    "title":"DevOps Engineer",
                    "description":"Creating Environment for application such as API to run on server and easier to develop as team. ",
                    "icon":"flaticon-language"
                },{
                    "title":"Lead Developer",
                    "description":"Creating development requirement and rules to be used by teams while joining on developing applications. Responsible to integrate and maintaining project structure and requirements.",
                    "icon":"flaticon-training"
                },{
                    "title":"Mobile Developer",
                    "description":"Experienced at developing mobile application with Java or Flutter.",
                    "icon":"flaticon-smartphone"
                },{
                    "title":"Backend Developer",
                    "description":"Developing APIs and Services to connect Frontend with Database. Currently experienced developing with Python, Node.JS, and PHP.",
                    "icon":"flaticon-innovation"
                },{
                    "title":"Frontend Web Developer",
                    "description":"Experienced on developing Frontend Web with React.JS Framework and Javascript + PHP (Fullstack).",
                    "icon":"flaticon-data"
                },{
                    "title":"Database Designer/Admin",
                    "description":"Experienced on building Database Structure. Currently controlling/designing Database in MariaDB and PostgreSQL Application.",
                    "icon":"flaticon-computer"
                },
            ]
        },
        "contact":{
            "title":"Contact",
            "subtitle":"Contact Me",
            "description":"",
            "contact_list":[
                {
                    "icon":"icon-phone2",
                    "title":"Contact Number",
                    "description":<a href="tel://6283178127408">+62-831-7812-7408</a>
                },{
                    "icon":"icon-paper-plane",
                    "title":"Email Address",
                    "description":<a href="mailto:luthfi.nabil@elemen.web.id">luthfi.nabil@elemen.web.id</a>
                },{
                    "icon":"icon-globe",
                    "title":"Website",
                    "description":<a href="https://elemen.web.id">elemen.web.id</a>
                },
            ]
        },
        "projects":{
            "title":"Project",
            "subtitle":"My Projects",
            "description":"Here is the Project lists that i have made.",
            "data":[
                {
                    "title":"DevOps Engineer",
                    "description":<a href="https://drive.google.com/file/d/14GPHdSh1GC8DxZ-UQov2KwJTFj-BVRLl/view?usp=sharing" target="_blank">Building CI/CD on SIMPRO Project with Gitlab</a>,
                    "bgImage":"images/devops.png"
                },{
                    "title":"Automated Unit Test",
                    "description":<a href="https://drive.google.com/file/d/1ho-ZdO277L_hgHFRAEbz6INMY2R-qnmK/view?usp=sharing" target="_blank">Setting Unit Tests to check application running before commiting</a>,
                    "bgImage":"images/unit_test.png"
                },{
                    "title":"Web Developer",
                    "description":<a href="https://drive.google.com/file/d/1pJlJTW0xCitibWe-M98PrthJh770MghU/view?usp=sharing" target="_blank">Developing SIMPRO Application for managing project activities</a>,
                    "bgImage":"images/web_dev.png"
                },{
                    "title":"Backend Developer",
                    "description":<a href="https://drive.google.com/file/d/1bToKfSuHZO0QDvHu1jdUaqEekjHVe3J_/view?usp=sharing" target="_blank">Connectiong Telegram Bot with another website application</a>,
                    "bgImage":"images/bot.png"
                },{
                    "title":"Fullstack Developer",
                    "description":<a href="https://drive.google.com/file/d/1tLSZcRLhbGoZOAYzVUYA3y9Usn411tqN/view?usp=sharing" target="_blank">Developing SSO to integrate data</a>,
                    "bgImage":"images/monitor.png"
                },{
                    "title":"DevOps Engineer",
                    "description":<a href="https://drive.google.com/file/d/1ACM_ARrIDgq32pEzhD2Pe8dh7Y1SSlL5/view?usp=sharing" target="_blank">Developing Server environment for application</a>,
                    "bgImage":"images/env.png"
                },
            ]
        },
        "footer":{
            "about_title":"About",
            "about_description":"Cuma developer backend yang bisa desain pake template saja."
        }
    }
}