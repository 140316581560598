import React from 'react';

export default class Services extends React.Component {
    render(){
        return (
            <section class="ftco-section" id="services-section">
                <div class="container-fluid px-md-5">
                    <div class="row justify-content-center py-5 mt-5">
                        <div class="col-md-12 heading-section text-center ftco-animate">
                            <h1 class="big big-2">{ this.props.strings.title }</h1>
                            <h2 class="mb-4">{ this.props.strings.subtitle  }</h2>
                            <p>{ this.props.strings.description }</p>
                        </div>
                    </div>
                    <div class="row">
                        { this.props.strings.projects.map(x=>{
                            return (
                                <div class="col-md-4 text-center d-flex ftco-animate">
                                    <a href="#" class="services-1 shadow">
                                        <span class="icon">
                                            <i class={x.icon}></i>
                                        </span>
                                        <div class="desc">
                                            <h3 class="mb-5">{ x.title}</h3>
                                            <p>{ x.description }</p>
                                        </div>
                                    </a>
                                </div>
                            );
                        })}
                        {/* <div class="col-md-4 text-center d-flex ftco-animate">
                            <a href="#" class="services-1 shadow">
                                <span class="icon">
                                    <i class="flaticon-language"></i>
                                </span>
                                <div class="desc">
                                    <h3 class="mb-5">DevOps Engineer</h3>
                                    <p>Creating Environment for application such as API to run on server and easier to develop as team. </p>
                                </div>
                            </a>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}