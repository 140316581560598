import React from 'react';
import Nav from './Nav';
import About from './components/About';
import Home from './components/Home';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Projects from './components/Projects';
import Resume from './components/Resume';
import Services from './components/Services';
import strings from './assets/strings.js';
class App extends React.Component {
  render(){
    return (
      <>
        <noscript>You need to enable JavaScript to run this app.</noscript>
        <div id="ftco-loader" className="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/></svg></div>
        <Nav strings={strings['en'].nav}/>
        <Home strings={strings['en'].home} />
        <About strings={strings['en'].about}/>
        <Resume strings={strings['en'].resume} />
        <Services strings={strings['en'].services}/>
        <Projects strings={strings['en'].projects }/>
        <Contact strings={strings['en'].contact}/>
        <Footer strings={strings['en']}/>
      </>
    )
  }
}

export default App;
