import React from 'react';

export default class Awards extends React.Component {
    render(){
        return (
            <div id="page-4" class= "page four">
                <h2 class="heading">{ this.props.strings.awards.title }</h2>
                { this.props.strings.awards.list.map(x=>{
                    return (
                        <div class="resume-wrap d-flex ftco-animate">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <span class="flaticon-experience"></span>
                            </div>
                            <div class="text pl-3">
                                <span class="date">{ x.year }</span>
                                <h2>{ x.title }</h2>
                                <span class="position">{ x.position }</span>
                                <p>{ x.description }</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}