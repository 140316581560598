import React from 'react';

export default class Skills extends React.Component {
    render(){
        return (
            <div id="page-3" class= "page three">
                <h2 class="heading">Skills</h2>
                <div class="row progress-circle mb-5">
                    <div class="col-lg-4 mb-4">
                        <div class="bg-white rounded-lg shadow p-4">
                        <h2 class="h5 font-weight-bold text-center mb-4">Javascript</h2>

                         {/* Progress bar 1 */} 
                        <div class="progress mx-auto" data-value='93'>
                            <span class="progress-left">
                            <span class="progress-bar border-primary"></span>
                            </span>
                            <span class="progress-right">
                            <span class="progress-bar border-primary"></span>
                            </span>
                            <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                            <div class="h2 font-weight-bold">93<sup class="small">%</sup></div>
                            </div>
                        </div>
                        {/* END */}

                        {/* Demo info */}
                        <div class="row text-center mt-4">
                            <div class="col-6 border-right">
                            <div class="h4 font-weight-bold mb-0">91%</div><span class="small text-gray">Last week</span>
                            </div>
                            <div class="col-6">
                            <div class="h4 font-weight-bold mb-0">92%</div><span class="small text-gray">Last month</span>
                            </div>
                        </div>
                        {/* END */}
                        </div>
                    </div>

                    <div class="col-lg-4 mb-4">
                        <div class="bg-white rounded-lg shadow p-4">
                        <h2 class="h5 font-weight-bold text-center mb-4">ExpressJS</h2>

                        {/* Progress bar 1 */}
                        <div class="progress mx-auto" data-value='91'>
                            <span class="progress-left">
                                <span class="progress-bar border-primary"></span>
                            </span>
                            <span class="progress-right">
                            <span class="progress-bar border-primary"></span>
                            </span>
                            <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                            <div class="h2 font-weight-bold">91<sup class="small">%</sup></div>
                            </div>
                        </div>
                        {/* END */}

                        {/* Demo info */}
                        <div class="row text-center mt-4">
                            <div class="col-6 border-right">
                            <div class="h4 font-weight-bold mb-0">91%</div><span class="small text-gray">Last week</span>
                            </div>
                            <div class="col-6">
                            <div class="h4 font-weight-bold mb-0">95%</div><span class="small text-gray">Last month</span>
                            </div>
                        </div>
                        {/* END */}
                        </div>
                    </div>

                    <div class="col-lg-4 mb-4">
                        <div class="bg-white rounded-lg shadow p-4">
                        <h2 class="h5 font-weight-bold text-center mb-4">Python</h2>

                        {/* Progress bar 1 */}
                        <div class="progress mx-auto" data-value='90'>
                            <span class="progress-left">
                            <span class="progress-bar border-primary"></span>
                            </span>
                            <span class="progress-right">
                            <span class="progress-bar border-primary"></span>
                            </span>
                            <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                            <div class="h2 font-weight-bold">90<sup class="small">%</sup></div>
                            </div>
                        </div>
                        {/* END */}

                        {/* Demo info */}
                        <div class="row text-center mt-4">
                            <div class="col-6 border-right">
                            <div class="h4 font-weight-bold mb-0">83%</div><span class="small text-gray">Last week</span>
                            </div>
                            <div class="col-6">
                            <div class="h4 font-weight-bold mb-0">94%</div><span class="small text-gray">Last month</span>
                            </div>
                        </div>
                        {/* END */}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 animate-box">
                            <div class="progress-wrap ftco-animate">
                                <h3>ReactJS</h3>
                                <div class="progress">
                                    <div class="progress-bar color-1" role="progressbar" aria-valuenow="90"
                                    aria-valuemin="0" aria-valuemax="100" style={{'width':'86%'}}>
                                    <span>86%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 animate-box">
                            <div class="progress-wrap ftco-animate">
                                <h3>SQL</h3>
                                <div class="progress">
                                    <div class="progress-bar color-2" role="progressbar" aria-valuenow="85"
                                    aria-valuemin="0" aria-valuemax="100" style={{'width':'85%'}}>
                                    <span>85%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 animate-box">
                            <div class="progress-wrap ftco-animate">
                                <h3>PHP</h3>
                                <div class="progress">
                                    <div class="progress-bar color-3" role="progressbar" aria-valuenow="84"
                                    aria-valuemin="0" aria-valuemax="100" style={{'width':'84%'}}>
                                    <span>84%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 animate-box">
                            <div class="progress-wrap ftco-animate">
                                <h3>Nginx</h3>
                                <div class="progress">
                                    <div class="progress-bar color-4" role="progressbar" aria-valuenow="82"
                                    aria-valuemin="0" aria-valuemax="100" style={{'width':'82%'}}>
                                    <span>82%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 animate-box">
                            <div class="progress-wrap ftco-animate">
                                <h3>C#</h3>
                                <div class="progress">
                                    <div class="progress-bar color-5" role="progressbar" aria-valuenow="80"
                                    aria-valuemin="0" aria-valuemax="100" style={{'width':'80%'}}>
                                    <span>80%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 animate-box">
                            <div class="progress-wrap ftco-animate">
                                <h3>Django</h3>
                                <div class="progress">
                                    <div class="progress-bar color-6" role="progressbar" aria-valuenow="71"
                                    aria-valuemin="0" aria-valuemax="100" style={{'width':'71%'}}>
                                    <span>71%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        );
    }
}