import React from 'react';

export default class Home extends React.Component {
    render(){
        var rotator = JSON.stringify(this.props.strings['data-rotate']);
        return (
            <section className="hero-wrap js-fullheight">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight justify-content-center align-items-center">
                        <div className="col-lg-8 col-md-6 ftco-animate d-flex align-items-center">
                            <div className="text text-center">
                                        <span className="subheading">{this.props.strings.intro}</span>
                                        <h1>{this.props.strings.name}</h1>
                                            <h2>{this.props.strings.intro_2}
                                                <span
                                                    className="txt-rotate"
                                                    data-period="2000"
                                                    data-rotate={ rotator }></span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="mouse">
                                    <a href="#" className="mouse-icon">
                                        <div className="mouse-wheel"><span className="ion-ios-arrow-round-down"></span></div>
                                    </a>
                                </div>
            </section>
               
        );
    }
}