import React from 'react';

export default class Nav extends React.Component {
    render(){
        return (
            <nav id="navi">
                <ul>
                    <li><a href="#page-1">{ this.props.strings.nav.education }</a></li>
                    <li><a href="#page-2">{ this.props.strings.nav.experience }</a></li>
                    <li><a href="#page-3">{ this.props.strings.nav.skills }</a></li>
                    <li><a href="#page-4">{ this.props.strings.nav.awards }</a></li>
                </ul>
            </nav>
        );
    }
}